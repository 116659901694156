<template>
  <div class="pa-0 no-scroll overflow-hidden" style="height: 100vh">
    <v-dialog max-width="400" persistent v-model="successDialog">
      <v-card color="success" class="text-center white--text">
        <v-container>
          <v-card-title class="text-center"
            >Registration Successful</v-card-title
          >
          <v-card-text
            v-html="successMessage ? successMessage : ''"
          ></v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
    <v-row class="h-100 overflow-hidden" no-gutters>
      <v-col
        class="hidden-sm-and-down"
        md="7"
        style="background-color: #0d01c7"
      >
        <img
          src="@/assets/img/bg.jpg"
          width="100%"
          height="100%"
          alt="bg-image"
          style="width: 100%; height: 100%"
        />
      </v-col>
      <v-col
        id="register"
        cols="12"
        sm="8"
        md="5"
        class="well-style mx-auto relative"
      >
        <div
          style="position: absolute; top: 0; left: 0; right: 0; z-index: 99"
          v-if="errorMessage"
          class="text-sm animate__animated animate__zoomInDown font-weight-bold d-flex justify-space-between error white--text pa-3"
        >
          <h5>{{ errorMessage ? errorMessage : '' }}</h5>
          <h5
            @click="errorMessage = null"
            class="cursor-pointer font-weight-bold"
          >
            X
          </h5>
        </div>
        <div class="d-flex mx-4 mt-4 justify-end align-center">
          <v-btn
            href="https://snappyexchange.com/rates"
            depressed
            color="accent"
            >Check Rates</v-btn
          >
        </div>
        <v-container class="d-flex align-center justify-center h-full mt-28">
          <v-card
            width="450"
            flat
            class="text-center animate__animated animate__zoomIn mt-5"
          >
            <v-img
              class="hidden-md-and-up"
              height="220"
              src="@/assets/img/bg.jpg"
            ></v-img>
            <v-col class="text-center">
              <a
                class="black--text text-subtitle-1 d-flex justify-center align-center mb-4"
                href="https://snappyexchange.com"
              >
                <img
                  :src="logoSnappy"
                  width="45"
                  height="45"
                  alt="Snappy Exchange"
                />
                <span class="font-weight-bold">SnappyExchange</span>
              </a>
              <h3 class="font-semibold">Welcome!</h3>
              <h5 class="font-weight-regular text-sm">
                Create your Snappy Exchange Account
              </h5>
            </v-col>
            <v-col>
              <v-form ref="registerForm" @submit.prevent="validate">
                <v-container>
                  <v-text-field
                    :disabled="loading"
                    autofocus
                    name="username"
                    type="text"
                    :rules="[
                      rules.required,
                      (v) =>
                        v.length >= 6 ||
                        'Username must to be 6 characters or more'
                    ]"
                    rounded
                    outlined
                    label="Username"
                    v-model.trim="form.username"
                  ></v-text-field>
                  <v-text-field
                    :disabled="loading"
                    name="email"
                    type="email"
                    rounded
                    outlined
                    label="Email"
                    :rules="[rules.required, rules.email]"
                    v-model="form.email"
                  ></v-text-field>

                  <v-text-field
                    :disabled="loading"
                    name="fname"
                    type="text"
                    rounded
                    label="First Name"
                    outlined
                    :rules="[rules.required]"
                    v-model="form.fname"
                  ></v-text-field>

                  <v-text-field
                    :disabled="loading"
                    name="lname"
                    type="text"
                    rounded
                    label="Last Name"
                    outlined
                    :rules="[rules.required]"
                    v-model="form.lname"
                  ></v-text-field>

                  <v-text-field
                    :disabled="loading"
                    name="phone"
                    type="text"
                    rounded
                    label="Phone Number"
                    outlined
                    :rules="[rules.required]"
                    v-model="form.phone"
                  ></v-text-field>

                  <v-select
                    :items="['Nigeria', 'Ghana', 'Kenya', 'Others']"
                    label="Country"
                    outlined
                    rounded
                    v-model="form.country"
                    :rules="[rules.required]"
                  ></v-select>

                  <v-text-field
                    :disabled="loading"
                    rounded
                    outlined
                    :type="showPassword ? 'text' : 'password'"
                    name="password"
                    label="Password"
                    @click:append="showPassword = !showPassword"
                    v-model="form.password"
                    :rules="[rules.minimum]"
                  ></v-text-field>
                  <v-text-field
                    :disabled="loading"
                    name="referral-code"
                    type="text"
                    rounded
                    outlined
                    v-model="form.referralCode"
                    hint="Username of the user that referred you"
                    persistent-hint
                  >
                    <template v-slot:label>
                      <div>Referral <small>(optional)</small></div>
                    </template></v-text-field
                  >
                  <div>
                    <p class="text-sm font-semibold text-left">
                      What is {{ add.num1 }}+{{ add.num2 }}
                    </p>
                    <v-text-field
                      label="Enter the Sum of the above operation here"
                      rounded
                      outlined
                      :rules="[
                        (v) =>
                          v == add.num2 + add.num1 || 'Enter the correct value'
                      ]"
                      v-model="resultSum"
                    >
                    </v-text-field>
                  </div>
                  <div class="my-1 mb-4">
                    <label class="">
                      <!-- <input
                        v-model="form.accepts"
                        class="w-4 h-4"
                        type="checkbox"
                        required
                      /> -->
                      <small class="text-sm text-gray-500 text-left d-block">
                        By clicking the SignUp button below, you agree to Snappy
                        Exchange
                        <a
                          class="primary--text"
                          target="_blank"
                          href="https://snappyexchange.com/privacy-policy"
                          >Privacy Policy</a
                        >
                        and
                        <a
                          class="primary--text"
                          target="_blank"
                          href="https://snappyexchange.com/terms-and-condition"
                          >Terms</a
                        >.
                      </small>
                      <!-- <small class="text-sm text-gray-500">
                        I agree to Snappy-eXchange
                        <a
                          class="primary--text"
                          target="_blank"
                          href="https://snappyexchange.com/privacy-policy"
                          >Privacy Policy</a
                        >
                        &amp;
                        <a
                          class="primary--text"
                          target="_blank"
                          href="https://snappyexchange.com/terms-and-condition"
                          >Terms</a
                        >.
                      </small> -->
                    </label>
                  </div>
                  <v-btn
                    class="mt-2"
                    :loading="loading"
                    type="submit"
                    block
                    rounded
                    color="primary"
                    elevation="2"
                    >Register</v-btn
                  >
                </v-container>
              </v-form>
            </v-col>
            <p
              class="subtitle-2 grey--text text--darken-2 font-weight-bold mb-10 my-0"
            >
              Already have an account?
              <router-link
                class="text-decoration-none grey--text text--darken-1 font-weight-bold accent--text"
                to="/login"
                >Click Here</router-link
              >
            </p>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import rules from '../../utils/rules'
import logoSnappy from '../../assets/logo.png'
import api from '../../api/users/auth'

export default {
  data() {
    return {
      successMessage: null,
      successDialog: false,
      errorMessage: null,
      logoSnappy,
      add: {},
      resultSum: 0,
      loading: false,
      showPassword: false,
      form: {
        email: '',
        password: '',
        phone: '',
        referralCode: '',
        username: ''
      },
      rules: {
        required: rules.required,
        email: rules.email,
        minimum: rules.valueWithAtLeast8Characters
      }
    }
  },
  created() {
    this.form.referralCode = this.$route.query.ref ? this.$route.query.ref : ''
    this.generateNumber()
  },
  watch: {
    'form.username': function (val) {
      this.form.username = val.toLowerCase().replace(/\s/g, '')
    }
  },
  methods: {
    validate() {
      if (this.$refs.registerForm.validate()) {
        this.register()
      }
    },
    generateNumber() {
      return (this.add = {
        num1: Math.floor(Math.random() * 10 + 1),
        num2: Math.floor(Math.random() * 10 + 1)
      })
    },
    async register() {
      this.loading = true
      const res = await api.auth().register({
        username: this.form.username,
        email: this.form.email,
        password: this.form.password,
        referrer: this.form.referralCode,
        lname: this.form.lname,
        fname: this.form.fname,
        phone: this.form.phone,
        country: this.form.country === 'Others' ? 'Nigeria' : this.form.country
      })

      if (res.error) {
        // this.errorMessage = res.errorMessage.message
        // this.successMessage = null
        this.loading = false
        this.$swal.fire({
          icon: 'error',
          title: 'Unable to register your account',
          text:
            res.errorMessage.message ||
            'Unable to register please check your internet connection'
        })
        document.getElementById('register').scrollTo(0, 0)
        return
      }

      this.errorMessage = null
      this.successMessage = `Registration successful, kindly check <b>${this.form.email}</b> to verify your email`
      this.loading = false
      this.successDialog = true
      // document.getElementById('register').scrollTo(0, 0)

      setTimeout(() => {
        this.$router.push(
          `/register/success?from=register&email=${this.form.email}`
        )
        this.loading = false
      }, 3000)
    }
  }
}
</script>

<style>
.well-style {
  overflow-y: scroll;
  height: 100vh;
  min-height: 100vh;
}

.no-scroll::-webkit-scrollbar {
  display: none;
}

.well-style::-webkit-scrollbar {
  width: 5px;
  border-radius: 15px;
}

.well-style::-webkit-scrollbar-thumb {
  width: 7px;
  background-color: #0d01c7;
}

.well-style::-webkit-scrollbar-track {
  widows: 5px;
  background-color: #fdca25;
}
</style>
